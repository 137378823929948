<template>

    <div class="container-fluid">
        <Head></Head>
        <Container></Container>
        <Bottom></Bottom>
    </div>

</template>

<script>
    import {Head, Container, Bottom} from './commponents'


    export default {
        metaInfo: {
        meta: [{                 
            name: '暹诺回收官网,手机回收,笔记本回收,数码产品回收,企业回收',
            content: '上海暹诺环保科技有限公司 (简称: 暹诺环保©)是中国领先的环保解决方案提供商，为国内500多家商业和工业企业客户提供回收服务。我们投资开发电子垃圾解决方案，用以适应不断变化的世界。目前该领域不仅包括处理和回收，还包括确保敏感数据的安全的方案，以帮助我们的客户实现零废物的绿色目标。作为中国领先的电子废弃物管理服务供应商，我们的服务宗旨是在最大限度降低影响的同时，又能最大限度地提高资源价值，为所有利益相关者促进经济和环境的可持续发展。'
        }]
        },
        name: "index",
        components: {Head, Container, Bottom},
        created() {

        }
    }
</script>

<style scoped>

    .container-fluid {
        padding-left: 0px;
        padding-right: 0px;
        overflow-x: hidden;
        width: 100% !important;

    }

</style>